/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.shouldUpdateScroll = () => {
    return [0, 0];
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
    if (document.querySelector(".instagram-media") !== null) {
        if (
            typeof gatsbyLoadInstagram !== `undefined` &&
            typeof window.gatsbyLoadInstagram === `function`
        ) {
            window.gatsbyLoadInstagram();
            if (window.instgrm) {
                window.instgrm.Embeds.process();
            }
        }
    }
};
